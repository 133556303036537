import { GenreFromApi, GetGenreProps } from '~/api/book/genreApiTypes';
import { provider } from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { ApiResponse, RejectedRequest } from '~/api/provider/providerTypes';

const namespace = Symbol('namespace');

export const genreApi = {
  [namespace]: 'v1/books/genres',

  async getGenre(data: GetGenreProps) {
    try {
      const result = await provider.get<
      ApiResponse<GenreFromApi, RejectedRequest<void>>
      >(`${this[namespace]}/${data.slug}`);

      if ('data' in result.data) {
        return result.data;
      }
    } catch (e) {
      const handleError = await handleRejectedRequest<void>(e);
      return handleError;
    }
  },
};
