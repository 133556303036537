import React, { FC } from 'react';
import styled from 'styled-components';

import { BookLayoutEnum } from '~/api/book/bookApiTypes';
import { GenreSortGridIcon } from '~/atomic/atom/icon/GenreSortGridIcon';
import { ListIcon } from '~/atomic/atom/icon/ListIcon';

export interface BookLayoutProps {
  layout: BookLayoutEnum;
  onChangeLayout(value: BookLayoutEnum): void;
}

export const BookLayout: FC<BookLayoutProps> = ({
  onChangeLayout,
  layout,
}) => {
  return (
    <SCButtons>
      <SCButton
        data-active={layout === BookLayoutEnum.GRID}
        onClick={() => onChangeLayout(BookLayoutEnum.GRID)}
      >
        <SCGenreSortGridIcon />
      </SCButton>
      <SCButton
        data-active={layout === BookLayoutEnum.LIST}
        onClick={() => onChangeLayout(BookLayoutEnum.LIST)}
      >
        <SCListIcon />
      </SCButton>
    </SCButtons>
  );
};

const SCButtons = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin-right: 8px;
`;

const SCButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: #1890ff;
  }

  &[data-active="true"] {
    border-color: #1890ff;
    i {
      color: #1890ff;
    }
  }
`;

const SCGenreSortGridIcon = styled(GenreSortGridIcon)`
  font-size: 20px;
  color: var(--gray-color);
`;

const SCListIcon = styled(ListIcon)`
  font-size: 20px;
  color: var(--gray-color);
`;
