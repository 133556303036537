import React from 'react';
import styled from 'styled-components';

import {
  BookCardRecommendation,
  BookCardRecommendationProps,
} from '~/feature/book/BookCard/BookCardRecommendation';

export const BookListRecommendation = ({
  book, isHideRecommendation, changeIsHideRecommendation, containerProps,
}: BookCardRecommendationProps) => {
  return (
    <SCBookCardRecommendation
      book={book}
      isHideRecommendation={isHideRecommendation}
      changeIsHideRecommendation={changeIsHideRecommendation}
      isShowModalFromProps
      containerProps={{
        ...containerProps,
        style: { width: 'auto' },
      }}
    />
  );
};

const SCBookCardRecommendation = styled(BookCardRecommendation)`
  background-color: inherit;
  width: auto;
  margin: 0;
`;
