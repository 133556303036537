import { createAsyncThunk } from '@reduxjs/toolkit';

import { WithToken } from '~/api/auth/authApiTypes';
import { bookApi } from '~/api/book/bookApi';
import { Book } from '~/api/book/bookApiTypes';
import { BookTag } from '~/api/book/bookTagApiTypes';
import { genreApi } from '~/api/book/genreApi';
import { Paginated } from '~/api/provider/providerTypes';
import { genrePageSelector } from '~/atomic/page/catalog/genrePage/genrePage.selector';
import {
  genrePageActions,
  genrePageSliceName,
} from '~/atomic/page/catalog/genrePage/genrePage.slice';
import { genresCheckboxForApiSelector, isAllFavoriteGenresIsActiveSelector } from '~/atomic/page/index/feed.selector';
import { createCatalogFilterUrl } from '~/feature/catalog/filter/catalogFilter.data';
import {
  catalogFilterGenresSelector,
  catalogFilterSelector,
} from '~/feature/catalog/filter/catalogFilter.selector';
import { catalogFilterActions } from '~/feature/catalog/filter/catalogFilter.slice';
import { paginationSelector } from '~/feature/pagination/pagination.selector';
import { paginationActions } from '~/feature/pagination/pagination.slice';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const getBooks = createAsyncThunk<
Paginated<Book[]> & { popularTags: BookTag[] },
WithToken | void,
{ rejectValue: { error: string }; state: RootState }>(
  `${genrePageSliceName}/getBookList`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(genrePageActions.setIsLoadBooks(true));

      const {
        completeStatus,
        bookType,
        isCanDownload,
        isOnlyFree,
        sortingType,
        withAudiobook,
        isExclusive,
        tagSlug,
        withAdvertisingFromBookriver,
        isWithAbonnement,
      } = catalogFilterSelector(thunkAPI.getState());
      const { perPage, page } = paginationSelector(thunkAPI.getState());

      const genresSlugs = catalogFilterGenresSelector(thunkAPI.getState());
      const genres = genresSlugs.join(',').replace(' ', '');
      const genresCheckboxForApi = genresCheckboxForApiSelector(thunkAPI.getState());
      const isAllFavoriteGenresIsActive = isAllFavoriteGenresIsActiveSelector(thunkAPI.getState());
      const isSendFavoriteGenres = genres.length === 0
        && !isAllFavoriteGenresIsActive;

      const result = await bookApi.getBookList({
        additionalGenres: genres.length > 0
          ? genres
          : undefined,
        favoriteGenres: isSendFavoriteGenres
          ? JSON.stringify({ genres: genresCheckboxForApi })
          : undefined,
        statusComplete: completeStatus,
        free: isOnlyFree ? true : undefined,
        download: isCanDownload ? true : undefined,
        exclusive: isExclusive ? true : undefined,
        withAudiobook: withAudiobook ? true : undefined,
        withAbonnement: isWithAbonnement ? true : undefined,
        type: bookType,
        tag: tagSlug === '' ? undefined : tagSlug,
        sortingType,
        hideAudiobooksWithoutEbooks: withAudiobook ? false : undefined,
        withAdvertisingFromBookriver: withAdvertisingFromBookriver ? true : undefined,
        page,
        perPage,
        token: data && data?.token ? data.token : undefined,
      });

      if (result && 'data' in result) {
        const popularTags = result.popularTags.map((tag) => {
          const urlWithTagResult = createCatalogFilterUrl(thunkAPI.getState(), { tagSlug: tag.slug });
          const urlWithoutTagResult = createCatalogFilterUrl(thunkAPI.getState(), { tagSlug: '' });

          return {
            ...tag,
            url: urlWithTagResult,
            withoutUrl: urlWithoutTagResult,
          };
        });

        thunkAPI.dispatch(paginationActions.initPagination({
          total: result.total,
          page: result.currentPage,
          perPage: result.perPage,
        }));

        thunkAPI.dispatch(genrePageActions.changeBooks(result.data));
        thunkAPI.dispatch(catalogFilterActions.changeTags(popularTags));

        return {
          ...result,
          popularTags: genres.length > 0 ? popularTags : [],
        };
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(genrePageActions.setIsLoadBooks(false));
    }
  },
);

export const updateGenreBooks = createAsyncThunk<
void,
{ book: Book },
{ state: RootState }
>(
  `${genrePageSliceName}/updateGenreBooks`,
  async (
    data,
    thunkAPI,
  ) => {
    const { books } = genrePageSelector(thunkAPI.getState());

    thunkAPI.dispatch(genrePageActions.changeBooks(books.map((book) => {
      if (book.id === data.book.id) {
        return data.book;
      }

      return book;
    })));
  },
);

export const getGenrePageDescription = createAsyncThunk<
void,
void,
{ state: RootState; }
>(
  `${genrePageSliceName}/getGenre`,
  async (data, thunkAPI) => {
    try {
      const genres = catalogFilterGenresSelector(thunkAPI.getState());

      if (genres.length === 1) {
        const result = await genreApi.getGenre({ slug: genres[0] });

        if (result && 'data' in result) {
          thunkAPI.dispatch(genrePageActions.changeGenreInfo(result.data));
        }
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  },
);
