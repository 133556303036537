import { unwrapResult } from '@reduxjs/toolkit';
import { ParsedUrlQueryInput } from 'querystring';
import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';

import { Book, CompleteStatusEnum } from '~/api/book/bookApiTypes';
import { Button } from '~/atomic/atom/Button';
import {
  AuthorLink,
  BookLink, ReaderLink, TagLink,
} from '~/atomic/atom/links/dynamicLinks';
import { SimpleCover } from '~/atomic/atom/SimpleCover';
import { BookStatus, SCStatus } from '~/atomic/molecula/BookStatus';
import { ListenAudioBookButton } from '~/atomic/page/book/Book/ListenAudioBookButton';
import { MyBooksStatusButton } from '~/atomic/page/book/Book/MyBooksStatusButton';
import { BookReaderThemeEnum } from '~/atomic/page/book-reader/reader.slice';
import { updateGenreBooks } from '~/atomic/page/catalog/genrePage/genrePage.data';
import {
  SCTextSmall,
  TextBoldStyle,
  TextRegStyle, TextTagStyle,
} from '~/atomic/Typography';
import { openLoginModal } from '~/feature/authorization/auth.data';
import { BookCardBadgers } from '~/feature/book/BookCard/BookCardBadgers';
import { BookCardExclusive } from '~/feature/book/BookCard/BookCardExclusive';
import { BookCardOverlayPrice } from '~/feature/book/BookCard/BookCardOverlayPrice';
import { BookCardReadStatusBadger } from '~/feature/book/BookCard/BookCardReadStatusBadger';
import { BookListRecommendation } from '~/feature/book/BookCard/BookListRecommendation';
import { getPublishedChapterListByBookSlugFromGraphql } from '~/feature/chapter/chapter.data';
import { chapterSelector } from '~/feature/chapter/chapter.selector';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { lessThan } from '~/lib/mediaQuery';
import { numberShorter } from '~/lib/numberShortest';
import { useAppDispatch, useAppSelector } from '~/store';

const DESC_MAX_LENGTH = 200;

const getSlicedDesc = (text) => {
  let sliced = text.slice(0, DESC_MAX_LENGTH);
  if (sliced.length < text.length) {
    sliced += '...';
  }
  return sliced;
};

interface BookListCardProps {
  book: Book;
  isFb2?: boolean;
  isShowGenres?: boolean;
  bookLinkQueries?: ParsedUrlQueryInput;
}

export const BookListCard = forwardRef<HTMLDivElement, BookListCardProps >(({
  book, isFb2, isShowGenres = true, bookLinkQueries,
}, ref) => {
  const dispatch = useAppDispatch();
  const [isHideRecommendation, changeIsHideRecommendation] = useState(
    book?.recommendation?.feedbackRating === false,
  );
  const [isAnnotationSliced, setIsAnnotationSliced] = useState(true);

  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { graphqlChapters } = useAppSelector(chapterSelector);
  const isLength = book.annotation.length > DESC_MAX_LENGTH;
  const isShowButtons = !isAnnotationSliced && isLength;

  const handleAnnotationButtonClick = () => setIsAnnotationSliced((prevState) => !prevState);

  const listenAudiobookHandler = async () => {
    unwrapResult(
      await dispatch(getPublishedChapterListByBookSlugFromGraphql({ slug: book.slug })),
    );
  };

  const onAddBookInMyBooksHandler = async (status) => {
    if (isLoggedIn) {
      dispatch(updateGenreBooks({
        book: {
          ...book,
          myBook: {
            ...book.myBook,
            readStatus: status,
          },
        },
      }));
    } else {
      dispatch(openLoginModal());
    }
  };

  return (
    <SCBookListCard ref={ref}>
      <SCBookWrap>
        <SCBookImage>
          <BookCardExclusive isExclusive={book.exclusive} />
          <BookCardBadgers
            bought={book?.myBook?.buy}
            discount={book?.discount}
            book={book}
            isShowPartnerProgram={false}
          />
          <BookCardReadStatusBadger status={book?.myBook?.readStatus} />
          <BookLink query={bookLinkQueries} slug={book.slug}>
            <SCLink>
              <SimpleCover
                width={160}
                font={{
                  min: 4,
                  max: 8,
                }}
                coverImages={book?.coverImages}
                book={{
                  author: book.authors?.[0]?.name ?? 'Имя автора',
                  title: book?.name ?? 'Название книги',
                }}
              />
            </SCLink>
          </BookLink>
        </SCBookImage>
        <SCBookMainInfo>
          <BookLink query={bookLinkQueries} slug={book.slug}>
            <SCName itemProp="name">{book.name}</SCName>
          </BookLink>
          <SCBookAuthors>
            {book.authors.map((author) => (
              <AuthorLink key={author.username} username={author.username}>
                <SCBookAuthor>{author.name}</SCBookAuthor>
              </AuthorLink>
            ))}
          </SCBookAuthors>
          <SCBookStatusInfo>
            <SCBookSize>
              {`${numberShorter(book?.symbols)} зн. ${isFb2 ? 'в FB2' : ''}`}
            </SCBookSize>
            <SCBookStatistic>
              {book.statusComplete === CompleteStatusEnum.Complete ? (
                <BookStatus type={CompleteStatusEnum.Complete} text="Полностью" />
              ) : (
                <BookStatus type={book.statusComplete} />
              )}
            </SCBookStatistic>
            <SCBookPriceWrap>
              <BookCardOverlayPrice book={book} />
            </SCBookPriceWrap>
            <BookListRecommendation
              book={book}
              isHideRecommendation={isHideRecommendation}
              changeIsHideRecommendation={changeIsHideRecommendation}
            />
          </SCBookStatusInfo>
          {isShowGenres ? (
            <SCTags>
              <SCTagsList>
                {book?.genres?.map((tag) => (
                  <SCTagItem key={tag.slug}>
                    <TagLink slug={tag.slug}>
                      <a itemProp="keywords">{tag.name}</a>
                    </TagLink>
                  </SCTagItem>
                ))}
              </SCTagsList>
            </SCTags>
          ) : null}
        </SCBookMainInfo>
        <SCBookSubInfo>
          <SCAnnotation>
            {isLength ? (
              <>
                {isAnnotationSliced ? getSlicedDesc(book.annotation) : book.annotation}
                <SCCollapseButton onClick={handleAnnotationButtonClick}>
                  {isAnnotationSliced ? 'раскрыть' : 'свернуть'}
                </SCCollapseButton>
              </>
            ) : (
              book.annotation
            )}
          </SCAnnotation>
          {isShowButtons && (
            <SCButtons>
              <ReaderLink query={bookLinkQueries} bookSlug={book.slug}>
                <SCLink>
                  <SCOpenBookButton size="normal" type="primary">
                    Читать книгу
                  </SCOpenBookButton>
                </SCLink>
              </ReaderLink>
              <SCListenAudioBookButton
                onClick={listenAudiobookHandler}
                book={{
                  name: book.name,
                  slug: book.slug,
                  id: book.id,
                  author: book.authors?.[0],
                  audiobook: {
                    bookId: book.id,
                    ...book.audiobook,
                  },
                }}
                chapters={graphqlChapters
                  ?.audioChapters
                  ?.map((chapter) => ({
                    id: chapter.chapterId,
                    name: chapter.name,
                    url: chapter?.audioUrl,
                    available: chapter?.available,
                    duration: chapter?.duration,
                  }))}
              />
              <SCMyBooksStatusButton
                onAddBookInMyBooks={onAddBookInMyBooksHandler}
                book={book}
              />
            </SCButtons>
          )}
        </SCBookSubInfo>
      </SCBookWrap>
    </SCBookListCard>
  );
});

const SCBookImage = styled.div`
  grid-area: image;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
  margin-right: 20px;
  width: 156px;
  box-sizing: border-box;

  ${lessThan('xs')} {
    width: 100px;
  }
`;

const SCName = styled.a`
  ${TextBoldStyle};
  color: var(--black-color);
  font-size: 17px;
  line-height: 18px;
  cursor: pointer;
  margin-bottom: 4px;

  ${lessThan('xs')} {
    font-size: 16px;
  }
`;

const SCBookStatusInfo = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 12px;
  row-gap: 8px;
`;

const SCBookStatistic = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  ${SCTextSmall} {
    margin-top: 4px;
  }

  ${SCStatus} {
    font-size: 10px;
    padding-left: 25px;
  }
`;

const SCBookMainInfo = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  
  > *:nth-child(2) {
    margin-bottom: 12px;
  }
  
  > *:not(:first-child):not(:nth-child(2)):not(:last-child) {
    margin-bottom: 6px;
  }
`;

const SCCollapseButton = styled.button`
  display: inline-block;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 8px;
`;

const SCTags = styled.div`
  display: flex;
  margin-bottom: 12px;

  ${lessThan('sm')} {
    flex-direction: column;
  }
`;

const SCTagsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  
  > * {
    &:not(:last-child) {
      margin-right: 5px;

      &:after {
        content: ",";
      }
    }
    &:first-child {
      &:after {
        content: "";
      }
    }
  }
`;

const SCTagItem = styled.li`
  list-style-type: none;
  color: var(--black-color);
  line-height: 15px;

  span,
  a {
    ${TextTagStyle};
    color: inherit;
    transition: color 0.3s ease;

    &:hover {
      color: var(--primary-hover-color);
    }
  }
  
  &:not(:last-child):after {
    content: ', ';
    display: inline;
  }
`;

const SCBookSubInfo = styled.div`
  grid-area: subinfo;

  ${lessThan('xs')} {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const SCBookPriceWrap = styled.div`
  font-weight: 700;
`;

const SCAnnotation = styled.p`
  ${TextRegStyle};
  margin: 0;
`;

const SCBookAuthors = styled.div`
  line-height: 16px;
`;

const SCBookAuthor = styled.a`
  ${TextRegStyle};
  line-height: 18px;
  transition: 0.3s;
  transition-property: color;
  color: var(--gray-color);

  &:hover {
    color: var(--primary-hover-color);
  }
`;

const SCOpenBookButton = styled(Button)`
  padding-left: 30px;
  padding-right: 30px;
`;

const SCBookListCard = styled.div`
  max-width: 1030px;
  width: 100%;

  &[data-theme=${BookReaderThemeEnum.Dark}] {
    ${SCTagItem} {
      color: var(--white-color);
    }

    ${SCName} {
      color: var(--white-color);
    }

    ${SCAnnotation} {
      color: var(--white-color);
    }
  }
`;

const SCBookWrap = styled.div`
  display: grid;
  grid-template-rows: minmax(90px, max-content) auto;
  grid-template-areas: "image info" "image subinfo";
  padding: 12px 14px;
  background-color: var(--white-color);
  border: 1px solid var(--bg-color);
  box-shadow: 0 1px 1px rgba(141, 147, 162, 0.25);
  border-radius: 4px;
  height: 100%;

  ${lessThan('sm')} {
    padding: 12px 12px 16px;
  }

  ${lessThan('xs')} {
    grid-template-rows: auto auto;
    grid-template-areas: "image info" "subinfo subinfo";
    grid-template-columns: minmax(80px, 100px) auto;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    justify-items: stretch;
  }
`;

const SCButtons = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 8px;
`;

const SCLink = styled.a`

`;

const SCMyBooksStatusButton = styled(MyBooksStatusButton)`
  max-height: 36px;
`;

const SCListenAudioBookButton = styled(ListenAudioBookButton)`
  height: 36px;
  max-width: 44px;
`;

const SCBookSize = styled.span`
  ${TextRegStyle};
`;
